@charset "UTF-8";
/*----------------------------------
--カラー設定
------------------------------------*/
/*--楽シリーズカラー--*/
/*----------------------------------
--style.cssに定義されているクラスの内容を変更
------------------------------------*/
@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=1d86a88e-4e86-4a81-8524-3fac1452d6a6");
body {
  font-family: Avenir , "Open Sans" , "Helvetica Neue" , Helvetica , Arial , Verdana , Roboto , "ヒラギノ角ゴ Pro W3" , "Hiragino Kaku Gothic Pro",  "Meiryo UI" , "メイリオ" , Meiryo ,"游ゴシック" , "Yu Gothic" , "游ゴシック体" , "YuGothic" , "Mplus 1p" , "Noto Sans Japanese" , "ＭＳ Ｐゴシック" , "MS PGothic" , sans-serif;
}

.body_form {
  max-width: 800px;
  margin: 0 auto;
  padding: 70px 30px;
}

p {
  color: #051A2B;
  line-height: 2;
  font-weight: 400;
}

h2,
h3,
h4 {
  line-height: 1.5;
  font-weight: 900;
}

.body_form #mailform #submit_btn,
.body_form form #submit_btn {
  float: none !important;
  margin: 15px auto 15px !important;
}

/*----------------------------------
--faq.cssに定義されているクラスの内容を変更
------------------------------------*/
.s-faq-grid__title {
  color: #777777;
}

.s-faq-grid__divider {
  border-bottom: 0px solid #777777;
}

.g-color--white-opacity {
  font-size: 0.9em;
  color: #A2A2A2 !important;
  line-height: 2;
}

/*----------------------------------
--process.scssに定義されているクラスの内容を変更
------------------------------------*/
.s-process-v1:before {
  background: none !important;
}

/*----------------------------------
--back-to-top.scssに定義されているクラスの内容を変更
------------------------------------*/
.s-back-to-top.-is-visible {
  bottom: 5rem;
}

/*----------------------------------
--faq.scssに定義されているクラスの内容を変更
------------------------------------*/
.s-mockup-v1 {
  right: 30px;
}

@media screen and (max-width: 992px) {
  .s-mockup-v1 {
    right: 0px;
  }
}

/*----------------------------------
--mockup.scssに定義されているクラスの内容を変更
------------------------------------*/
.raku-faq-Q {
  display: inline;
  width: 10%;
  padding-right: 10px;
  padding-bottom: 10px;
}

.raku-faq-Q-2 {
  position: relative;
  bottom: 10px;
}

.raku-faq-Q-text {
  display: inline-block;
}

@media screen and (max-width: 992px) {
  .raku-faq-Q-text {
    display: inline-block;
    width: 90%;
  }
  .raku-faq-Q-text-2line-sp {
    position: relative;
    bottom: 10px;
  }
  .raku-faq-Q-text-3line-sp {
    position: relative;
    bottom: 24px;
  }
}

.row-eq-height {
  display: flex;
  flex-wrap: wrap;
}

.raku-faq {
  margin-bottom: 20px;
}

.raku-faq-tilte {
  display: inline;
}

.raku-faq-A-text {
  line-height: 1.5;
  margin-top: 10px;
  margin-bottom: 30px;
}

.cbp .cbp-item {
  overflow: visible;
}

/*----------------------------------
--楽テック用のクラス定義
------------------------------------*/
/* Frutigerの設定 */
@font-face {
  font-family: "Frutiger Next W01 Light";
  src: url("/webfont/ed851bde-4b1b-45ca-9fbb-9603819932cf.eot?#iefix");
  src: url("/webfont/ed851bde-4b1b-45ca-9fbb-9603819932cf.eot?#iefix") format("eot"), url("/webfont/fa8815a0-4d7b-4eb5-8184-9d9ff16708b2.woff2") format("woff2"), url("/webfont/35288d5e-681e-4b64-a930-438d2b2551d6.woff") format("woff"), url("/webfont/1a22c75e-4022-43cd-a543-70ca36eb92aa.ttf") format("truetype"), url("/webfont/2c937a24-6a4c-47b2-b3f3-28173e19b53d.svg#2c937a24-6a4c-47b2-b3f3-28173e19b53d") format("svg");
}

@font-face {
  font-family: "Frutiger Next W01 Bold";
  src: url("/webfont/ce9ebe9b-6684-4783-8760-f3a20a0c52f0.eot?#iefix");
  src: url("/webfont/ce9ebe9b-6684-4783-8760-f3a20a0c52f0.eot?#iefix") format("eot"), url("/webfont/983d9248-775f-41ea-ad59-cee5bdbb6147.woff2") format("woff2"), url("/webfont/5f3f5b96-b77d-497d-a916-d483bc9c6c3f.woff") format("woff"), url("/webfont/209c5dbd-a0e8-4fdf-ada6-50628360eefd.ttf") format("truetype"), url("/webfont/44583fec-16e5-46d4-94b5-f779465dc4ec.svg#44583fec-16e5-46d4-94b5-f779465dc4ec") format("svg");
}

.yakumono {
  font-weight: normal;
}

/*PC用の改行タグ*/
.br-pc {
  display: block;
}

/*スマホ用の改行タグ*/
.br-sp {
  display: none;
}

@media screen and (max-width: 768px) {
  /*PC用の改行タグ*/
  .br-pc {
    display: none;
  }
  /*スマホ用の改行タグ*/
  .br-sp {
    display: block;
  }
}

::selection {
  background: #52c6e2;
  /* Safari */
}

::-moz-selection {
  background: #52c6e2;
  /* Firefox */
}

.img-f-left {
  float: right;
}

.img-center {
  margin: 0 auto;
}

a[target="_blank"]:after {
  /*---content: url(/img/svg/ico_blank-01.svg);
	margin: 0px 5px; うるさかったので、一旦アイコン無し*/
}

.raku-service h3 {
  border-top: 2px solid #52c6e2;
  padding-top: 20px;
  padding-bottom: 10px;
}

.raku-service p:last-child {
  border-bottom: 2px solid #52c6e2;
  padding-bottom: 20px;
}

.raku-service_img_sp {
  padding-top: 40px;
}

.text-underline {
  text-decoration: none;
  border-bottom: 2px solid #ede939;
  padding-bottom: 5px;
}

.raku-shisaku {
  background-color: #063D69;
}

.raku-shisaku table {
  width: 100%;
  border: solid 1px white;
  border-collapse: collapse;
  color: white;
}

.raku-shisaku table td {
  vertical-align: middle;
  padding: 5px 10px;
  border: solid 1px white;
}

.raku-shisaku table .raku-bg-blue {
  background-color: #004CA5;
}

.raku-shisaku table .raku-up {
  background-color: #ede939;
  color: #004CA5;
}

.raku-shisaku table .raku-table-title {
  font-size: 0.8em;
  text-align: center;
}

.raku-shisaku table .raku-table-child td:nth-child(1) {
  font-size: 0.8em;
}

.raku-shisaku table .raku-table-child td:nth-child(2) {
  text-align: right;
}

.raku-shisaku table .raku-table-child td:nth-child(3),
.raku-shisaku table .raku-table-child td:nth-child(4) {
  text-align: center;
}

.raku-merrit-list ul li {
  float: left;
  margin: 30px  60px 30px 0px;
  text-align: center;
}

.raku-merrit-list ul li img {
  margin-bottom: 15px;
}

@media screen and (max-width: 992px) {
  .raku-merrit-list ul li {
    margin: 30px 0px;
    display: block;
    width: 33%;
    font-size: 0.7em;
    line-height: 1.5;
  }
}

.raku-frutiger-right {
  font-family: "Frutiger Next W01 Light";
}

.raku-frutiger-bold {
  font-family: "Frutiger Next W01 BOLD";
}

.raku-contact-telephone {
  margin: 0 auto;
  margin-top: 80px;
  max-width: 800px;
  padding: 60px 15px;
}

.raku-img-contact {
  position: relative;
  top: 3px;
}

/*----------------------------------
--footerに関する記述
------------------------------------*/
.ft_corpLink .box_corp {
  -webkit-transition: all .3s;
  transition: all .3s;
}

.ft_corpLink .box_corp:hover {
  cursor: pointer;
  opacity: 0.5;
  color: #52c6e2;
}

.ft_menu ul li {
  font-size: 0.9em;
  padding-bottom: 1em;
}

.ft_menu ul li a {
  -webkit-transition: all .3s;
  transition: all .3s;
}

.ft_menu ul li a:hover {
  cursor: pointer;
  opacity: 0.5;
  color: #52c6e2;
}

.ft_menu ul li:first-child {
  font-size: 0.8em;
  font-weight: bold;
  padding-bottom: 2em;
}

/*----------------------------------
--header(PC版)に関する記述
------------------------------------*/
.pc-header {
  padding: 20px;
  float: right;
}

.pc-header ul li {
  font-weight: bold;
  float: left;
  padding-right: 30px;
}

.pc-header ul li a {
  -webkit-transition: all .3s;
  transition: all .3s;
}

.pc-header ul li a:hover {
  cursor: pointer;
  color: #52c6e2;
}

.s-header__navbar-row-col {
  width: 10% !important;
}

.s-header__logo-img {
  width: 110px;
}

.raku-contact {
  background-color: #ede939;
  padding: 15px;
  border-radius: 5px;
  /*CSS3草案 */
  -webkit-border-radius: 5px;
  /*Safari,Google Chrome用 */
  -moz-border-radius: 5px;
  /*Firefox用 */
  -webkit-transition: all .3s;
  transition: all .3s;
}

@media screen and (max-width: 992px) {
  .raku-contact {
    background-color: #ede939;
    margin-top: 20px;
    padding: 20px 20px 30px 20px;
    color: #051A2B;
    /*border-radius: 5px;        CSS3草案 */
    /*-webkit-border-radius: 5px;   Safari,Google Chrome用 */
    /*-moz-border-radius: 5px;    Firefox用 */
  }
  .s-header__logo-img {
    width: 72px;
  }
  .s-header__navbar-row-col {
    width: 95% !important;
  }
}
